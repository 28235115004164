import { H2 } from '@/common/ui'
import { CompanyAvatar } from '@/equities/companyAvatar'
import {
    BriefcaseIcon,
    CheckIcon,
    HandshakeIcon,
    MailIcon,
    MegaphoneIcon,
    NewspaperIcon,
    UsersIcon,
} from 'lucide-react'
import moment from 'moment'
import { useState } from 'react'

type TargetCompanyMilestones = {
    code: string
    name: string
    milestones: Milestone[]
}

type Milestone = {
    title: string
    date: string
}

export function CampaignTimeline({ targetCompanyMilestones }: { targetCompanyMilestones: TargetCompanyMilestones[] }) {
    const [selectedCompany, setSelectedCompany] = useState<TargetCompanyMilestones>(targetCompanyMilestones[0])
    if (targetCompanyMilestones?.length === 0 || !targetCompanyMilestones) return null
    let milestoneOrder = [
        'campaign-launched',
        '100-shareholders',
        'send-company-demands',
        'lodge-resolution',
        'agm-showdown',
        'results-announcement',
    ]

    const hunderedShareholdersDate = selectedCompany?.milestones.find(
        (milestone) => milestone.title === '100-shareholders',
    )?.date
    const sendCompanyDemandsDate = selectedCompany?.milestones.find(
        (milestone) => milestone.title === 'send-company-demands',
    )?.date

    if (moment(hunderedShareholdersDate).isAfter(moment(sendCompanyDemandsDate))) {
        milestoneOrder = [
            'campaign-launched',
            'send-company-demands',
            '100-shareholders',
            'lodge-resolution',
            'agm-showdown',
            'results-announcement',
        ]
    }

    const milestones = selectedCompany?.milestones
    const orderedMilestones = milestones.sort((a, b) => {
        return milestoneOrder.indexOf(a.title) - milestoneOrder.indexOf(b.title)
    })

    const currentMilestone = orderedMilestones.find((milestone) => moment(milestone.date).isAfter(moment()))

    return (
        <section className="max-w-full w-full sm:max-w-[600px] mt-12 ">
            <H2>The timeline</H2>
            <CompanyGroup
                targetCompanyMilestones={targetCompanyMilestones}
                onSelectCompany={(company) => setSelectedCompany(company)}
                selectedCompany={selectedCompany}
            />
            <div className="w-full h-[604px] px-6 py-8 bg-[#e3fbc5] rounded-[10px] flex-col justify-start items-center gap-2 inline-flex">
                {orderedMilestones.map((milestone) => (
                    <Milestone milestone={milestone} currentMilestone={currentMilestone} />
                ))}
            </div>
        </section>
    )
}

export function CompanyGroup({
    targetCompanyMilestones,
    onSelectCompany,
    selectedCompany,
}: {
    targetCompanyMilestones: TargetCompanyMilestones[]
    onSelectCompany: (company: TargetCompanyMilestones) => void
    selectedCompany: TargetCompanyMilestones
}) {
    const noOfCompanies = targetCompanyMilestones.length
    const lastCompanyIndex = noOfCompanies - 1

    return (
        <div className="flex rounded-full w-full border-neutral-200 mb-4 border flex-row p-1">
            {targetCompanyMilestones.map((company, index) => (
                <button className={`${lastCompanyIndex === index ? '' : 'border-r '} w-full p-1`}>
                    <div
                        className={`w-full p-2.5 ${
                            selectedCompany?.code === company.code ? 'bg-lime-100' : 'hover:bg-lime-50'
                        }  rounded-[100px] justify-center items-center gap-1 flex`}
                        onClick={() => onSelectCompany(company)}
                    >
                        <div className={`w-8 h-8 self-center relative ${noOfCompanies >= 5 && 'sm:block hidden'}`}>
                            <CompanyAvatar securityCode={company.code} />
                        </div>
                        <span className={`${noOfCompanies > 3 && 'text-xs'} text-left ml-1`}>
                            {' '}
                            <span className="block sm:hidden">{company.code}</span>
                            <span className="hidden sm:block">{company.name}</span>
                        </span>
                    </div>
                </button>
            ))}
        </div>
    )
}

export function Milestone({
    milestone,
    currentMilestone,
}: {
    milestone: { title: string; date: string }
    currentMilestone?: Milestone
}) {
    let milestoneText
    let icon
    switch (milestone.title) {
        case 'campaign-launched':
            milestoneText = 'Launch campaign'
            icon = <MegaphoneIcon color="white" />
            break
        case '100-shareholders':
            milestoneText = 'Obtain 100 shareholders'
            icon = <UsersIcon color="white" />
            break
        case 'send-company-demands':
            milestoneText = 'Send company our demands'
            icon = <MailIcon color="white" />
            break
        case 'lodge-resolution':
            milestoneText = 'Lodge resolution'
            icon = <NewspaperIcon color="white" />
            break
        case 'agm-showdown':
            milestoneText = 'AGM showdown'
            icon = <BriefcaseIcon color="white" />
            break
        case 'results-announcement':
            milestoneText = 'Announce results'
            icon = <HandshakeIcon color="white" />
            break

        default:
            milestoneText = milestone.title
    }

    const is100Shareholders = milestone.title === '100-shareholders'
    const isPast = moment(milestone.date).isBefore(moment())
    const isFirst = milestone.title === 'campaign-launched'
    const isCurrent = currentMilestone?.title === milestone.title

    return (
        <div className={`${!isFirst && 'grow'} shrink basis-0 self-stretch items-start gap-4 flex`}>
            <div className="flex h-full gap-2 flex-col">
                {!isFirst && (
                    <div
                        className={`w-1 rounded-full h-full ${isPast || isCurrent ? 'bg-[#223a32]' : 'bg-[#CCC3B6]'} self-center`}
                    />
                )}
                <div
                    className={`w-[50px] min-h-[50px] p-2.5 ${isPast || isCurrent ? 'bg-[#223a32]' : 'bg-[#CCC3B6]'} rounded-full justify-center items-center gap-2.5 flex`}
                >
                    <div className="w-6 h-6 relative">{isPast ? <CheckIcon color="white" /> : icon}</div>
                </div>
            </div>
            <div className="grow shrink basis-0 h-[65px] flex-col self-end -mt-3 gap-1 inline-flex">
                <div className="text-neutral-600 text-sm font-medium leading-tight">
                    {is100Shareholders && !isPast ? (
                        <span className="font-semibold">By {moment(milestone.date).format('DD MMM YYYY H:mm a')}</span>
                    ) : (
                        moment(milestone.date).format('DD MMM YYYY')
                    )}
                </div>
                <div className="self-stretch justify-start items-center gap-2.5 inline-flex grow">
                    <div className="text-black text-md font-medium leading-7 mb-3">{milestoneText}</div>
                </div>
            </div>
        </div>
    )
}
