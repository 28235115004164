import { FC, ReactNode } from 'react'

import { EquityT, Maybe } from '@/common/types'
import { H3 } from '@/common/ui/h3'
import { inMillionsOrBillions } from '@/common/utils/utils'
import Money from '@/common/models/money'

type KeyStatisticItemProps = {
    label: string
    value: ReactNode
}
const EquityInsightItem: FC<KeyStatisticItemProps> = ({ label, value }) => {
    return (
        <li className="flex space-x-1 items-end last:border-none justify-between px-2 py-3 border-b border-neutral-300">
            <span className="text-sm text-left">{label}</span>
            <strong className="text-sm font-semibold text-right">{value}</strong>
        </li>
    )
}

type Props = {
    statistics: Maybe<EquityT['statistics']>
    about: Maybe<EquityT['about']>
    // dayRange: Maybe<[number, number]>
    yearRange: Maybe<[number, number]>
}
export const EquityInsights: FC<Props> = ({ statistics, about, yearRange }) => {
    if (!statistics || !about) return

    return (
        <aside className="flex flex-col gap-8">
            <div>
                <H3 className="">Key Statistics</H3>
                <div className="w-full bg-[#F9F9F9] rounded py-3">
                    <ul className="w-full px-4 rounded-xl bg-gray-100">
                        {statistics.dayOpenedAt && <EquityInsightItem label="Open" value={statistics.dayOpenedAt} />}
                        {/* {dayRange && (
                        <EquityInsightItem
                            label="Day Range"
                            value={`${Money.of(dayRange[0])} - ${Money.of(dayRange[1])}`}
                        />
                    )} */}
                        {yearRange && (
                            <EquityInsightItem
                                label="52 week range"
                                value={`${Money.of(yearRange[0])} - ${Money.of(yearRange[1])}`}
                            />
                        )}
                        {statistics.marketCapitalisation && (
                            <EquityInsightItem
                                label="Market Cap"
                                value={`$${inMillionsOrBillions(statistics.marketCapitalisation)}`}
                            />
                        )}
                        {statistics.volume && (
                            <EquityInsightItem label="Avg. volume" value={inMillionsOrBillions(statistics.volume)} />
                        )}
                        {statistics.peRatio && statistics.peRatio > 0 && (
                            <EquityInsightItem label="P/E ratio" value={statistics.peRatio} />
                        )}
                        {statistics.dividend && (
                            <EquityInsightItem label="Dividend yield" value={statistics.dividend} />
                        )}
                    </ul>
                </div>
            </div>
            <div>
                <H3 className="">About</H3>
                <div className="w-full bg-[#F9F9F9] rounded">
                    <ul className="w-full px-4 py-4 rounded-xl bg-gray-100">
                        {about.ceo && <EquityInsightItem label="CEO" value={about.ceo} />}
                        {about.sector && <EquityInsightItem label="Sector" value={about.sector} />}
                        {about.industry && <EquityInsightItem label="Industry" value={about.industry} />}
                        {about.foundedYear && <EquityInsightItem label="Founded" value={about.foundedYear} />}
                        {about.webAddress && (
                            <EquityInsightItem
                                label="Website"
                                value={
                                    <a href={`https://${about.webAddress}`} target="_blank" className="break-all">
                                        {about.webAddress}
                                    </a>
                                }
                            />
                        )}
                    </ul>
                </div>
            </div>
        </aside>
    )
}
