import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useFetchEquityDetails, useFetchHistoricalPrice } from '@/trading/tradingQueries'
import { EquityInsights } from './equityInsights'
import { getPriceRange } from '@/common/utils/utils'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP } from '@/common/strings'
import { EquityDetailsPageHeader } from './EquityDetailsPageHeader'
import { EquityPriceAndGraph } from './EquityPriceAndGraph'
import { H2 } from '@/common/ui'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'

export const EquityDetails = () => {
    const { securityCode } = useParams()
    const { showCompanyProfilePages } = useFeatureFlags()

    const { data: equity } = useFetchEquityDetails(securityCode)
    const { data: yearPricePonits } = useFetchHistoricalPrice(equity?.securityCode, '1Y')

    const yearRange = useMemo(() => (yearPricePonits ? getPriceRange(yearPricePonits) : null), [yearPricePonits])
    if (!securityCode || !equity) return <FullScreenLoader className="h-20" />

    if (!showCompanyProfilePages) return null

    return (
        <>
            <Helmet>
                <title>
                    {securityCode} | {APP.title}
                </title>
            </Helmet>
            <div className="sticky top-0 bg-white">
                <EquityDetailsPageHeader securityName={equity.description} />
            </div>
            <main className="flex flex-col gap-8">
                <div className="grid grid-cols-1 xl:grid-cols-[2fr_1fr] gap-8">
                    <EquityPriceAndGraph securityCode={securityCode} />
                    <EquityInsights yearRange={yearRange} statistics={equity.statistics} about={equity.about} />
                </div>
                <H2 className="mt-10">Company Snapshot</H2>
                <div>
                    <p className="mb-4">{equity.about.description}</p>
                    <p>{equity.about.history}</p>
                </div>
            </main>
        </>
    )
}
