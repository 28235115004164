import { Popover, PopoverContent, PopoverTrigger } from '@/common/ui/popover'
import { ChevronDown } from 'lucide-react'
import { Button } from '../ui'
import { User as Account } from 'lucide-react'
import { MessageCircle } from 'lucide-react'
import { LogOut } from 'lucide-react'
import { signOut } from 'aws-amplify/auth'
import { APP_ROUTES } from '../constants'
import { useNavigate } from 'react-router-dom'
import { User } from '../types'
import { analytics } from '../analytics/analytics'

type Props = {
    user: User
}

export default function ProfileDropdown(props: Props) {
    const { user } = props
    const navigate = useNavigate()
    const avatarContent =
        user.firstName && user.lastName ? `${user.firstName[0]}${user.lastName[0]}` : `${user.firstName[0]}`

    const trackOpenChange = (isOpen: boolean) => {
        isOpen && analytics.track(`ProfileDropdown Opened`)
    }

    return (
        <Popover onOpenChange={trackOpenChange}>
            <PopoverTrigger asChild>
                <Button variant="outline" className="h-auto py-1 px-1">
                    <div className="flex items-center">
                        <div className="h-10 w-10 bg-primary text-white rounded-full flex items-center justify-center">
                            {avatarContent.toUpperCase()}
                        </div>
                        <div className="hidden md:block pl-2">{`${user.firstName} ${user.lastName[0]}`}</div>
                        <div className="px-2">
                            <ChevronDown />
                        </div>
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80 p-2" align="end">
                <div>
                    <ul>
                        <li
                            className="py-1.5 px-2 flex cursor-pointer hover:bg-accent rounded"
                            onClick={() => navigate(APP_ROUTES.PROFILE)}
                        >
                            <Account />
                            <span className="ml-1">Account Settings</span>
                        </li>
                        <li
                            className="py-1.5 px-2 flex cursor-pointer hover:bg-accent rounded"
                            onClick={() => navigate(APP_ROUTES.SUPPORT)}
                        >
                            <MessageCircle />
                            <span className="ml-1">Contact Support</span>
                        </li>
                        <div className="w-full h-px bg-muted"></div>
                        <li
                            className="py-1.5 px-2 flex cursor-pointer hover:bg-accent rounded"
                            onClick={() => signOut()}
                        >
                            <LogOut />
                            <span className="ml-1">Log out</span>
                        </li>
                    </ul>
                </div>
            </PopoverContent>
        </Popover>
    )
}
