import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { ColumnDef } from '@tanstack/react-table'
import { debounce } from '@/common/utils/utils'
import { DataTable } from '@/common/ui/data-table'
import { InstrumentT, TopMarketDataResponse } from '@/common/types'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { getTopCompanies, searchInstruments } from '@/trading/tradingApi'
import { TOP_COMPANIES_LIST_PAGE_SIZE } from '@/common/config'
import { Button } from '@/common/ui/button'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP } from '@/common/strings'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { CompanyHead } from '@/equities/companyHead'
import { InstrumentsSearchForm } from '@/equities/instrumentsSearchForm'
import { APP_ROUTES } from '@/common/constants'
import { H1 } from '@/common/ui'

export function Research() {
    const [topCompanies, setTopCompanies] = useState<TopMarketDataResponse | null>(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [searchResult, setSearchResult] = useState<InstrumentT[] | null>(null)
    const { showCompanySearch, showTradingPage } = useFeatureFlags()

    // @ts-ignore
    const columns: ColumnDef<InstrumentT>[] = useMemo(
        () => [
            {
                accessorKey: 'name',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Company</span>
                },
                cell: ({ row }) => {
                    const securityCode = row.original.securityCode
                    return (
                        <Link to={`/equity/${securityCode}`} className="text-inherit">
                            <CompanyHead
                                nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                                instrument={row.original}
                            />
                        </Link>
                    )
                },
            },
            {
                accessorKey: 'esgProfile',
                header: () => {
                    return <span className="font-bold text-black">Ethical Profile</span>
                },
                cell: ({ row }) => (
                    <Link to={`${APP_ROUTES.RESEARCH}/${row.original.securityCode}`} className="text-inherit">
                        <Button variant="outline" className="flex space-x-3">
                            View
                        </Button>
                    </Link>
                ),
                minSize: '100px',
                maxSize: '500px',
                enableSorting: false,
            },
        ],
        [],
    )

    const fetchInstruments = useCallback((query: string) => {
        searchInstruments(query).then((res) => {
            setSearchResult(res.companies)
        })
    }, [])

    useEffect(() => {
        getTopCompanies(pageNumber).then((data) => setTopCompanies(data))
    }, [pageNumber])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceOnChange = useCallback(debounce(fetchInstruments, 500), [])

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value
        setSearchTerm(query)
        if (query.length === 0) onInputClear()
        if (query.length < 3) return
        debounceOnChange(query)
    }

    const onInputClear = () => {
        setSearchTerm('')
        setSearchResult(null)
    }

    if (!topCompanies) return <FullScreenLoader />

    const totalPages = Math.ceil(topCompanies.totalCount / TOP_COMPANIES_LIST_PAGE_SIZE)

    const prevPage = () => {
        setPageNumber((n) => (n === 1 ? n : n - 1))
    }

    const nextPage = () => {
        setPageNumber((n) => (n === totalPages ? n : n + 1))
    }

    if (!showTradingPage) return null

    return (
        <div>
            <Helmet>
                <title>Research | {APP.title}</title>
            </Helmet>
            <H1>Research</H1>
            {showCompanySearch && (
                <InstrumentsSearchForm value={searchTerm} onChange={onSearchChange} onClear={onInputClear} />
            )}
            <div className="mt-6">
                <DataTable columns={columns} data={searchResult ? searchResult : topCompanies.companies} />
            </div>
            {!searchResult && (
                <div className="mt-6 flex justify-between">
                    <Button onClick={prevPage} variant="outline" className="flex space-x-3">
                        <ChevronLeft size={16} />
                        Prev
                    </Button>
                    <span>{`${topCompanies.page} / ${totalPages}`}</span>
                    <Button onClick={nextPage} variant="outline" className="flex space-x-3">
                        Next
                        <ChevronRight size={16} />
                    </Button>
                </div>
            )}
        </div>
    )
}
